import { render, staticRenderFns } from "./threepay_tiexin.vue?vue&type=template&id=38eca222&scoped=true"
import script from "./threepay_tiexin.vue?vue&type=script&lang=js"
export * from "./threepay_tiexin.vue?vue&type=script&lang=js"
import style0 from "./threepay_tiexin.vue?vue&type=style&index=0&id=38eca222&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38eca222",
  null
  
)

export default component.exports